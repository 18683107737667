import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiUrl } from '../config'
import cogoToast from "cogo-toast";
import { redirect } from 'react-router-dom';

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
}

// get all employee in employee directory
export const employeeDirectory = createAsyncThunk(
  'employeeDirectory',
  async ({ page, type, page_size }, { rejectWithValue, dispatch }) => {
    try {
      if (type === "all") {
        const res = await axios.get(`${apiUrl}auth/employees?page_size=${page_size}&page=${page}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
            }
          }
        )
        if (res.status === 200) {
          return res.data
        }
      } else {
        const res = await axios.get(`${apiUrl}auth/employees?status=${type}&page_size=${page_size}&page=${page}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
            }
          }
        )
        if (res.status === 200) {
          return res.data
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      }
      else {
        return rejectWithValue(error.response)
      }
    }
  }
)

//get an employee list of payslips
export const listEmployeePayslip = createAsyncThunk(
  'listEmployeePayslip',
  async ({ id, page, page_size }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}admin/employee/${id}/payslips?page_size=${page_size}&page=${page}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          }
        }
      )
      if (res.status === 200) {
        return res.data
      }

    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      }
      else {
        return rejectWithValue(error.response)
      }
    }
  }
)

//list employee allowance
export const listEmployeeAllowance = createAsyncThunk(
  'listEmployeeAllowance',
  async ({ id, page, page_size }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}admin/employee/${id}/allowance?page_size=${page_size}&page=${page}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          }
        }
      )
      if (res.status === 200) {
        return res.data
      }

    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      }
      else {
        return rejectWithValue(error.response)
      }
    }
  }
)

//retrieve employee payslip on admin
export const retrieveEmployeePayslip = createAsyncThunk(
  'retrieveEmployeePayslip',
  async ({ employee_id, payslip_id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}admin/employee/${employee_id}/payslips/${payslip_id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          }
        }
      )
      if (res.status === 200) {
        return res.data
      }

    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      }
      else {
        return rejectWithValue(error.response)
      }
    }
  }
)

//get employee account information
export const retrieveActiveEmployee = createAsyncThunk(
  'employeeAccountInformation',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}employee/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          }

        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      }
      else {
        return rejectWithValue(error.response)

      }
    }
  }
)

//download EmployeePayslip
export const downloadEmployeePayslip = createAsyncThunk(
  'downloadEmployeePayslip',
  async ({ id, date, payslip }, { rejectWithValue, dispatch }) => {
    try {
      let url = `${apiUrl}payslip/${id}/download/?date_of_entry=${date}`
      const res = await axios.get(`${apiUrl}payslip/${id}/download/?date_of_entry=${date}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
          responseType: 'blob'
        }
      )
        .then((response) => {
          url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${payslip}.pdf`); // Replace with the desired filename to save the PDF
          document.body.appendChild(link);
          link.click();
          link.remove();
          cogoToast.success(`Payslip download successfull!`)
        })
      if (res.status === 200) {
        return res.data
      }

    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      }
      else {
        cogoToast.error(`${error.response.data.error}!`);
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)

//send employee payslip
export const sendEmployeePayslip = createAsyncThunk(
  'sendEmployeePayslip',
  async ({ id, date }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}payslip/${id}/send_to_email/?date_of_entry=${date}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if ([200, 201, 202].includes(res.status)) {
        cogoToast.success(res.data.message || "Email was sent successfully!");
        return res.data
      }


    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      }
      else {
        cogoToast.error(`${error.response.data.error}!` || "Internal server error. Please try again later.");
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)

//edit employee profile
export const editEmployeeProfile = createAsyncThunk(
  "editEmployeeProfile",
  async ({ data, id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.patch(
        `${apiUrl}auth/${id}/edit_employee_profile/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success(res.data.message);
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response.status === 400) {
        cogoToast.error(`${error.response.date.error}`)
      } else {
        cogoToast.error(`${error.response.data.error}`);
        return rejectWithValue(error.response);
      }
    }
  }
);

//add new record
export const addNewRecord = createAsyncThunk(
  "addNewRecord",
  async ({ data }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}auth/add_new_employee_data/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success(res.message || "Paytime Invitation has been successfully sent to new employee email.");
        return res.data;
      }

    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response.status === 400) {
        const { staff_id, email } = error.response.data.error || {};
        const errorMessage = staff_id && email
          ? 'Both staff ID and email already exist.'
          : staff_id
            ? 'Staff ID already exists.'
            : email
              ? 'Email already exists.'
              : 'Oops! Employee details was not saved.';

        cogoToast.error(errorMessage);
      } else {
        cogoToast.error(`${error.response.data.error}`);
        return rejectWithValue(error.response);
      }
    }
  }
);

//update employee status
export const updateEmployeeStatus = createAsyncThunk(
  'updateEmployeeStatus',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}auth/${id}/update-status/`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
        }
      )
      if (res.status === 200) {
        cogoToast.success(`${res.data.message}`)
        return res.data
      }

    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      }
      else {
        cogoToast.error(`${error.response.data.error}!`);
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)

//pay salary advance
export const paySalaryAdvance = createAsyncThunk(
  "paySalaryAdvance",
  async ({id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}payslip/${id}/pay-advance-salary/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success(res.data.message.message || "Advance Payment Successful!");
        return res.data;
      }

    } catch (error) {
      if (error.response.status === 401) {
        redirect('/login');
        //dispatch({ type: "auth/Logout" });
      } else if (error.response.status === 400) {
        cogoToast.error(error.response.data.error);
      } else {
        cogoToast.error(`${error.response.data.error}`);
        return rejectWithValue(error.response);
      }
    }
  }
);