import React, { useEffect, useState } from "react";
import { ContentCopy } from "@mui/icons-material";
import {  toggleView } from "../redux/Auth/auth";
import { userProfile } from "../redux/Auth/authAction";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/header";
import cogoToast from "cogo-toast";
import { useNavigate } from "react-router-dom";
import GoBack from "../components/goBack";
import ModalComponent from "../components/Modals/modal";
import { editEmployeeProfile } from "../redux/Auth/authAction";

const ProfilePage = () => {

  const [show, setShow] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  const [reload, setReload] = useState(false)

  let dispatch = useDispatch()

  let navigate = useNavigate()

  const { profile, first_name, last_name, email, user_id, is_admin, is_superuser} = useSelector((state) => state.auth)
  //const [admin, setAdmin] = useState(false)
  useEffect(() => {
    dispatch(userProfile())
    setReload(false)
  }, [dispatch, reload])

  console.log(user_id)

  const handleCopyNumber = (id) => {
    navigator.clipboard.writeText(id);
    cogoToast.success(`${id} copied successfully`)
  }

  const handleSwitchView = () => {
    dispatch(toggleView())
    if (is_admin) {
      navigate('/payslip')
    } else {
      navigate('/overview')
    }
  }

  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.startsWith("234")) {
      return "+" + phoneNumber;
    } else if (phoneNumber.startsWith("0")) {
      return "+234" + phoneNumber.slice(1);
    } else if (!phoneNumber.startsWith("+234")) { // Check if it *doesn't* start with +234
      return "+234" + phoneNumber;
    }
    return phoneNumber; // Return original if already correctly formatted
  };
  
  const handleUpdatePhoneNumber = async() => {
    setIsLoading(true)
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
  
    let data = {
      phone_number: formattedPhoneNumber,
    };
  
   try {
    const result = await dispatch(editEmployeeProfile({ data, user_id }));

    // Check for success (using createAsyncThunk's fulfilled action type)
    if (editEmployeeProfile.fulfilled.match(result)) {
      setShow(false);
      setPhoneNumber("");
      setReload(true)
     // cogoToast.success(result.payload.message || "Phone number updated successfully!"); // Use message from payload
    } else {
      // Handle rejected case (using createAsyncThunk's rejected action type)
      if (editEmployeeProfile.rejected.match(result)) {
          console.error("Update failed:", result.payload);
          //const errorMessage = result.payload?.data?.error || result.error?.message || "Failed to update. Please try again.";
        //  cogoToast.error(errorMessage);

      } else {
          console.error("Unexpected result:", result);
        //  cogoToast.error("An unexpected error occurred.");
      }
    }
  } catch (error) {
      console.error("A general error occurred:", error);
      cogoToast.error("A general error occurred. Please try again later.");
  } finally {
    setIsLoading(false);
  }
   }

  const showModal = () => {
    setShow(false)
  }

  return (
    <>
      <ModalComponent
        showModal={showModal}
        show={show}
        title={"Update Phone Number"}
        subtitle={"To ensure seamless payment to your Crop2Cash wallet, please update your profile with the phone number linked to your Crop2Cash wallet"}>
        <div>
          <div className="flex items-center justify-center gap-6 mt-4">
            <div>
              <p className="text-secondary text-sm font-normal">
                Enter the phone number linked to your Crop2Cash wallet/account
              </p>
              <input
                type="phone"
                placeholder="Update Phone Number"
                name="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-full font-medium text-sm"
              />
            </div>
          </div>
          <div className="mt-4 flex justify-center align-center items-center">
            <button
              onClick={handleUpdatePhoneNumber}
              disabled={isLoading}
              className="outline-none rounded-lg disabled:text-[white] disabled:bg-[#ABB6AC] bg-primary text-white py-3 px-5 text-sm font-medium border border-primary hover:bg-[#24752B] hover:bg-opacity-[0.9]"
            >
              {isLoading ? "Loading..." : "Submit Phone Number"}
            </button>
          </div>
        </div>
      </ModalComponent>
      <GoBack />
      <Header text="Profile Information">
        <div className="flex gap-4">
        {!is_admin && is_superuser &&<div className="">
              <button
                type="submit"
                disabled="" //hover color is #24752B
                className="bg-transparent disabled:bg-[#ABB6AC] border-[1px] rounded-lg w-full py-2.5 px-5 text-primary border-primary font-medium hover:bg-[#24752B] hover:text-bold hover:text-white transition-all text-sm"
                onClick={() => (handleSwitchView())}
              >
                Admin View
              </button>
            </div>}
            {is_admin && is_superuser && <div className="">
              <button
                type="submit"
                disabled="" //hover color is #24752B
                className="bg-transparent disabled:bg-[#ABB6AC] border-[1px] rounded-lg w-full py-2.5 px-5 text-primary border-primary font-medium hover:bg-[#24752B] hover:text-bold hover:text-white transition-all text-sm"
                onClick={() => (handleSwitchView())}
              >
                Employee View
              </button>
            </div>}
            <button
              type="submit"
              disabled="" //hover color is #24752B
              className="bg-primary disabled:bg-[#ABB6AC] border-[1px] rounded-lg w-full py-2.5 px-5 text-white border-primary font-medium hover:bg-[#24752B] hover:text-bold hover:text-white transition-all text-sm"
              onClick={() => setShow(true)}
            >
              Update Profile
            </button>
            </div>
      </Header>
      <div className="bg-white rounded-[8px] p-4 mt-6">
        <div className="flex items-center gap-[8px] mb-[24px]">
          {/* FARMER"S INITIALS */}
          <div className="bg-green-100 rounded-full w-[48px] h-[48px] flex items-center justify-center">
            <p className="font-medium text-base leading-6 text-center text-green-700 ">
              {first_name ? first_name.charAt(0) : "N/A"}
              {last_name ? last_name.charAt(0) : "N/A"}
            </p>
          </div>

          <div className="">
            <p className="text-secondary font-semibold leading-none text-sm">
              {first_name ? first_name : "N/A"}{" "}
              {last_name ? last_name : "N/A"}
            </p>
            <div
              className="flex gap-[6px] mt-[4px] cursor-pointer"
              onClick={() =>
                handleCopyNumber(
                  `${profile.employee_id !== null
                    ? profile.employee_id
                    : "N/A"
                  }`
                )
              }
            >
              <p className="text-primary font-semibold leading-tight text-sm cursor-pointer">
                {profile !== null
                  ? profile.employee_id
                  : "N/A"}
              </p>
              <ContentCopy
                className="text-primary cursor-copy"
                style={{ fontSize: "18px" }}
              />
            </div>
          </div>
        </div>

        <div className="flex  flex-wrap gap-17">
          <div className="mb-[24px] w-1/5">
            <p className="font-normal text-sm leading-tight text-tertiary pb-[4px] ">
              First Name
            </p>
            <p className="font-semibold text-sm leading-5 text-[#344335] ">
              {first_name ? first_name : 'N/A'}
            </p>
          </div>
          <div className="mb-[24px]  w-1/5">
            <p className="font-[400} text-sm leading-5 text-[#7C8D7E] pb-[4px]">
              Last Name
            </p>
            <p className="font-semibold text-sm leading-5 text-[#344335]">
              {last_name ? last_name : 'N/A'}
            </p>
          </div>
          <div className="mb-[24px]  w-1/5">
            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
              Email Address
            </p>
            <p className="font-semibold text-sm leading-5 text-[#344335] break-words">
              {email ? email : "N/A"}
            </p>
          </div>
          <div className="mb-[24px]  w-1/5">
            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
              Employee ID
            </p>
            <p
              className="font-semibold text-sm leading-5 text-[#344335]"
              onClick={() =>
                handleCopyNumber(
                  `${profile.employee_id !== null
                    ? profile.employee_id
                    : "N/A"
                  }`
                )
              }
            >
              {profile.employee_id !== null
                ? profile.employee_id
                : "N/A"}{" "}
              <ContentCopy
                className="text-[#4B4E68] cursor-copy"
                style={{ fontSize: "18px" }}
              />
            </p>
          </div>
          <div className="mb-[24px]  w-1/5">
            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
              Department
            </p>
            <p className="font-semibold text-sm leading-5 text-[#344335]">
              {profile.department ? profile.department : 'N/A'}
            </p>
          </div>
          <div className="mb-[24px]  w-1/5">
            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
              Role
            </p>
            <p className="font-semibold text-sm leading-5 text-[#344335]">
              {profile.role ? profile.role : 'N/A'}
            </p>
          </div>
          <div className="mb-[24px]  w-1/5">
            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
              Account Name
            </p>
            <p className="font-semibold text-sm leading-5 text-[#344335]">
              {profile.account_name ? profile.account_name : 'N/A'}
            </p>
          </div>
          <div className="mb-[24px]  w-1/5">
            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
              Account Number
            </p>
            <p
              className="font-semibold text-sm leading-5 text-[#344335]"
              onClick={() =>
                handleCopyNumber(
                  `${profile
                    ? profile.account_number
                    : "N/A"
                  }`
                )
              }
            >
              {profile ? profile.account_number : 'N/A'}
              <ContentCopy
                className="text-[#4B4E68] cursor-copy"
                style={{ fontSize: "18px" }}
              />
            </p>
          </div>
          <div className="mb-[24px]  w-1/5">
            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
              Bank
            </p>
            <p className="font-semibold text-sm leading-5 text-[#344335]">
              {profile ? profile.bank : 'N/A'}
            </p>
          </div>
          <div className="mb-[24px]  w-1/5">
            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
              Pension ID
            </p>
            <p
              className="font-semibold text-sm leading-5 text-[#344335]"
              onClick={() =>
                handleCopyNumber(
                  `${profile.pension_id !== null
                    ? profile.pension_id
                    : "N/A"
                  }`
                )
              }
            >
              {profile.pension_id !== ""
                ? profile.pension_id
                : "N/A"}

              {profile.pension_id !== null &&
                <ContentCopy
                  className="text-[#4B4E68] cursor-copy"
                  style={{ fontSize: "18px" }}
                />}
            </p>
          </div>
          {profile?.phone_number && <div className="mb-[24px]  w-1/5">
            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
              Phone Number
            </p>
            <p
              className="font-semibold text-sm leading-5 text-[#344335]"
              onClick={() =>
                handleCopyNumber(
                  `${profile.phone_number !== null
                    ? profile?.phone_number
                    : "N/A"
                  }`
                )
              }
            >
              {profile.phone_number !== ""
                ? profile.phone_number
                : "N/A"}

              {profile.phone_number !== null &&
                <ContentCopy
                  className="text-[#4B4E68] cursor-copy"
                  style={{ fontSize: "18px" }}
                />}
            </p>
          </div>}
        </div>
      </div>
    </>
  );

}
export default ProfilePage;
