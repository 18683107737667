import { createSlice } from '@reduxjs/toolkit'
import { refreshToken } from './authAction'
import { userLogin, userRegister, verifyEmail, verifyAccountNumber, getBanks, userProfile, getRoles, getDepartments, resetPassword, validateOtp, editEmployeeProfile } from './authAction'
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  value: 0,
  loading: false,
  verifyLoader: false,
  token: "",
  isAuthenticated: false,
  userInfo: null,
  first_name: "",
  last_name: "",
  email: "",
  user_id: "",
  is_admin: false,
  is_superuser: false,
  refreshToken: "",
  signup_success: false,
  role: '',
  employee_id: '',
  pension_id: '',
  banks: [],
  roles: [],
  account_name: '',
  department: '',
  profile: {},
  departments: [],
  isFailed: false,
  isSuccess: false,
  isValidOtp : false,
  success: false
}

const authPersistConfig = {
  key: "auth",
  storage,
  blacklist: ["loading"]
}


export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    Logout: (state) => {
      state.isAuthenticated = false
      localStorage.setItem('token', '');
      localStorage.setItem('refreshToken', '');
      localStorage.clear();
      sessionStorage.clear();
    },
    clearSignUpSuccess: (state) => {
      state.signup_success = false
    },
    clearEditSuccess: (state) => {
      state.success = false
    },
    toggleView: (state) => {
      state.is_admin = !state.is_admin
    },
    clearGetResetLinkSuccess: (state) => {
      state.isSuccess = false
    }
  },
  extraReducers: {
    //refresh token
    [refreshToken.pending]: (state) => {
      state.loading = true
    },
    [refreshToken.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.isAuthenticated = true
      state.first_name = payload.data.first_name;
      state.last_name = payload.data.last_name;
      state.email = payload.data.email;
      state.userInfo = payload.data.user_profile;
      state.user_id = payload.data.user_id;
      state.is_admin = payload.data.is_admin;
      state.refreshToken = payload.refreshToken;
      state.token = payload.token;
      localStorage.setItem('token', payload.token);
      // localStorage.setItem('refreshToken', payload.refreshToken);
    },
    [refreshToken.rejected]: (state) => {
      state.loading = false
      state.isAuthenticated = false
    },
    // login user -------------------- ---------------------------------------------------------------
    [userLogin.pending]: (state) => {
      state.loading = true
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.isAuthenticated = true
      state.first_name = payload.data.first_name
      state.last_name = payload.data.last_name
      state.email = payload.data.email
      state.userInfo = payload.data.user_profile
      state.user_id = payload.data.user_id
      state.is_admin = payload.data.is_admin
      state.is_superuser = payload.data.is_superuser
      state.refreshToken = payload.refreshToken
      state.token = payload.token
      localStorage.setItem('token', payload.token);
      localStorage.setItem('refreshToken', payload.refreshToken)
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false
      state.isAuthenticated = false
    },
    // register user -------------------- ---------------------------------------------------------------
    [userRegister.pending]: (state) => {
      state.loading = true
    },
    [userRegister.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.signup_success = true
    },
    [userRegister.rejected]: (state, { payload }) => {
      state.loading = false
    },
    //verify email
    [verifyEmail.pending]: (state) => {
      state.verifyLoader = true
    },
    [verifyEmail.fulfilled]: (state, { payload }) => {
      state.verifyLoader = false
      state.role = payload.role
      state.employee_id = payload.employee_id
      state.pension_id = payload.pension_id
      state.department = payload.department
    },
    [verifyEmail.rejected]: (state) => {
      state.verifyLoader = false
    },
    //verify account number
    [verifyAccountNumber.pending]: (state) => {
      state.loading = true
      state.verifyLoader = true
    },
    [verifyAccountNumber.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.verifyLoader = false
      state.account_name = payload.account_name
    },
    [verifyAccountNumber.rejected]: (state) => {
      state.loading = false
      state.verifyLoader = false
    },
    //get Banks dropdown API
    [getBanks.pending]: (state) => {
    },
    [getBanks.fulfilled]: (state, { payload }) => {
      state.banks = payload.data.sort((a, b) => a.bank_name > b.bank_name ? 1 : -1)
    },
    [getBanks.rejected]: (state) => {
      state.loading = false
    },
    //get user profile
    [userProfile.pending]: (state) => {
      state.loading = true
    },
    [userProfile.fulfilled]: (state, {payload}) => {
      
      state.loading = false
       state.profile = payload.data
    },
    [userProfile.rejected]: (state) => {
      state.loading = false
    },
    //get roles
    [getRoles.pending]: (state) => {
    },
    [getRoles.fulfilled]: (state, { payload }) => {
      state.roles = payload.data.sort((a, b) => a.bank_name > b.bank_name ? 1 : -1)
    },
    [getRoles.rejected]: (state) => {
      state.loading = false
    },
    //get departments
    [getDepartments.pending]: (state) => {
    },
    [getDepartments.fulfilled]: (state, { payload }) => {
      state.departments = payload.data.sort((a, b) => a.bank_name > b.bank_name ? 1 : -1)
    },
    [getDepartments.rejected]: (state) => {
      state.loading = false
    },
    //reset password
    [resetPassword.pending]: (state) => {
      state.loading = true
    },
    [resetPassword.fulfilled]: (state) => {
      state.loading = false
      state.isSuccess = true
      state.isFailed = false

    },
    [resetPassword.rejected]: (state) => {
      state.loading = false
      state.isSuccess = false
      state.isFailed = true
    },
    //validate otp
    [validateOtp.pending]: (state) => {
      state.loading = true
      state.isValidOtp = false
    },
    [validateOtp.fulfilled]: (state) => {
      state.loading = false
      state.isValidOtp= true
    },
    [validateOtp.rejected]: (state) => {
      state.loading = false
      state.isValidOtp = false
    },
    //employee profile 
    [editEmployeeProfile.pending]: (state) => {
      state.loading = true
      state.success = false
    },
    [editEmployeeProfile.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
    },
    [editEmployeeProfile.rejected]: (state) => {
      state.loading = false
      state.success = false
    },
  },
})

const authReducer = persistReducer(authPersistConfig, authSlice.reducer);
// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount, Logout, clearSignUpSuccess, toggleView, clearGetResetLinkSuccess, clearEditSuccess } = authSlice.actions

export default authReducer