import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiUrl } from '../config'
import cogoToast from 'cogo-toast'
import { redirect } from 'react-router-dom'


const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

//get pension table
export const pensionTable = createAsyncThunk(
    'pensionTable',
    async({page, month, year, page_size}, {rejectWithValue, dispatch}) => {
     try {
        const res = await axios.get(`${apiUrl}admin/pensions/?page_size=10&page=${page}&month=${month}&year=${year}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
        )
    if(res.status === 200){
        return res.data
    }
    
  } catch (error) {
    if(error.response.status === 401){
      cogoToast.error("Authentication credentials were not provided.")
      dispatch({type: 'auth/Logout'})
      redirect('/login')
    }
    else{
      return rejectWithValue(error.response)
    }
  }
    }
);

//get pension schedule
export const pensionSchedule = createAsyncThunk(
  'pensionSchedule',
  async({month, year, page, page_size}, {rejectWithValue, dispatch}) => {
   try {
      const res = await axios.get(
        `${apiUrl}pension/schedules/?year=${year}&month=${month}&page_size=${page_size}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
  if(res.status === 200){
      return res.data
  }
  
} catch (error) {
  if(error.response.status === 401){
    dispatch({type: 'auth/Logout'})
  }
  else{
    return rejectWithValue(error.response)
  }
}
  }
)

//update pension schedule
export const updatePensionSchedule = createAsyncThunk(
  'updatePensionSchedule',
  async({id, voluntary_contribution, employee_contribution, employer_contribution}, {rejectWithValue, dispatch}) => {
  try {
    const res = await axios.post(`${apiUrl}pension/${id}/update-schedule/`,
      {
        voluntary_contribution: voluntary_contribution,
        employee_contribution: employee_contribution,
        employer_contribution: employer_contribution
      },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    if (res.status === 200) {
      cogoToast.success(`"Remittance updated successfully!`);
      return res.data;
      
    }
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({ type: "auth/Logout" });
    } else if (error.response.status === 400){
      cogoToast.error(`${error.response.data.error || error.response.data.detail}`)
    } else {
      return rejectWithValue(error.response.data.error);
    }
  }
}
)

//get pension payout
export const pensionPayout = createAsyncThunk(
  'pensionPayout',
  async( {rejectWithValue, dispatch}) => {
   try {
      const res = await axios.get(`${apiUrl}pension/payouts`,
      {
          headers:{
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}` 
          }
      }
      )
  if(res.status === 200){
      return res.data
  }
  
} catch (error) {
  if(error.response.status === 401){
    dispatch({type: 'auth/Logout'})
  }
  else{
    return rejectWithValue(error.response)
  }
}
  }
)

//update pension payout
export const updatePensionPayout = createAsyncThunk(
  'updatePensionPayout',
  async({id, amount}, {rejectWithValue, dispatch}) => {
  try {
    const res = await axios.post(`${apiUrl}pension/${id}/update-payout/`, {amount: amount},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    if (res.status === 200) {
      cogoToast.success(`${res.data.message}`);
      return res.data;
    }
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({ type: "auth/Logout" });
      
    } else if (error.response.status === 400){
      
      cogoToast.error(`${error.response.data.detail || error.response.data.error}`)
    } else {
      return rejectWithValue(error.response.data.error);
    }
  }
}
)

//download pension schedule
export const downloadPensionSchedule = createAsyncThunk(
  "downloadPensionSchedule",
  async ({ contribution_date }, { rejectWithValue, dispatch }) => {
    try {
      let url = `${apiUrl}download_pension`;
      const res = await axios
        .get(
          `${apiUrl}pension/download-schedule/?contribution_date=${contribution_date}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Pension Schedule.csv`); // Replace with the desired filename to save the PDF
          document.body.appendChild(link);
          link.click();
          link.remove();
          cogoToast.success(`Pension download successfull!`);
        });
      if (res.status === 200) {
        cogoToast.success(`Pension download successfull!`);
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response.status === 400) {
        cogoToast.error(
          `Error due to missing records or invalid contribution date`
        );
      } else {
        cogoToast.error(`Error while downloading pension table!`);
        return rejectWithValue(error.response);
      }
    }
  }
);


//download pension payout receipt as pdf
export const downloadPensionPayoutReceipt= createAsyncThunk(
  'downloadPensionPayoutReceipt',
  async({id, contribution_date}, {rejectWithValue, dispatch}) => {
   try { 
      let url = `${apiUrl}download_pension`
      const res = await axios.get(`${apiUrl}pension/${id}/download-payout-receipt/`,
      {
          headers:{
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}` 
          },
          responseType: 'blob'
      }
      )
      .then((response) => {
         url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Pension Payout Receipt for ${contribution_date}.pdf`); // Replace with the desired filename to save the PDF
        document.body.appendChild(link);
        link.click();
        link.remove();
        cogoToast.success(`Pension Payout download successfull!`);
      })
  if(res.status === 200){
      cogoToast.success(`Pension download successfull!`);
      return res.data
  }
  
} catch (error) {
  if(error.response.status === 401){
    dispatch({type: 'auth/Logout'})
  } else if(error.response.status === 400){
    cogoToast.error(`Error due to missing records or invalid contribution date`)
  }
  else{
      cogoToast.error(`Error while downloading pension table!`);
    return rejectWithValue(error.response)
  }
  }
}
)

//disburse payout
export const disbursePayout = createAsyncThunk(
  'disbursePayout',
  async({id}, {rejectWithValue, dispatch}) => {
  try {
    const res = await axios.get(`${apiUrl}pension/payouts/disburse/?payout_id=${id}`, 
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }
    }
  );
    if (res.status === 200) {
      cogoToast.success(`Payout is being processed...!`);
      return res.data;
    }
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({ type: "auth/Logout" });
    } else if (error.response.status === 400){
      cogoToast.error(`${error.response.data.error}`)
    } else {
      return rejectWithValue(error.response.data.error);
    }
  }
}
)

//download pension table as csv
export const downloadPensionTable = createAsyncThunk(
  "downloadPension",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      let url = `${apiUrl}download_pension`;
      const res = await axios
        .get(`${apiUrl}admin/pensions/download`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          responseType: "blob",
        })
        .then((response) => {
          url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Pension Table.csv`); // Replace with the desired filename to save the PDF
          document.body.appendChild(link);
          link.click();
          link.remove();
          cogoToast.success(`Pension download successfull!`);
        });
      if (res.status === 200) {
        cogoToast.success(`Pension download successfull!`);
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        cogoToast.error(`Error while downloading pension table!`);
        return rejectWithValue(error.response);
      }
    }
  }
);
