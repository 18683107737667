import { createSlice } from '@reduxjs/toolkit'
import { disbursePayout, downloadPensionPayoutReceipt, pensionPayout, pensionSchedule, pensionTable } from './pensionAction'


const initialState = {
  value: 0,
  total: 0,
  loading: false,
  loader: false,
  pension: [],
  schedules: [],
  payouts: [] || {},
  remittance_updated: false,
  receipt_success : false
}

export const pensionSlice = createSlice({
  name: "pension",
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
  },
  extraReducers: {
    // get pension table
    [pensionTable.pending]: (state) => {
      state.loading = true;
      state.loader = true;
    },
    [pensionTable.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.total = payload.count;
      state.loader = false;
      state.pension = payload.results;
    },
    [pensionTable.rejected]: (state) => {
      state.loading = false;
      state.loader = false;
    },
    [pensionSchedule.pending]: (state) => {
      state.loading = true;
      state.loader = true;
    },
    [pensionSchedule.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.loader = false;
      state.total = payload.count;
      state.schedules = payload.data;
    },
    [pensionSchedule.rejected]: (state) => {
      state.loading = false;
      state.loader = false;
    },
    [pensionPayout.pending]: (state) => {
      state.loading = true;
      state.loader = true;
    },
    [pensionPayout.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.loader = false;
      state.total = payload.count;
      state.payouts = payload.data;
    },
    [pensionPayout.rejected]: (state) => {
      state.loading = false;
      state.loader = false;
    },
    [disbursePayout.pending]: (state) => {
      state.loading = true;
      state.loader = true;
    },
    [disbursePayout.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.loader = false
    },
    [disbursePayout.rejected]: (state) => {
      state.loading = true
      state.loader = true
    },
    [downloadPensionPayoutReceipt.pending]: (state) => {
      state.loading = true
      state.remittance_updated = false
      state.receipt_success = false
    },
    [downloadPensionPayoutReceipt.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.remittance_updated = true
      state.receipt_success = true
    },
    [downloadPensionPayoutReceipt.rejected]: (state) => {
      state.loading = true
     state.remittance_updated = false
     state.receipt_success = false
    },

  }
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = pensionSlice.actions;

export default pensionSlice.reducer;
