// import React, {
//   useState, useEffect
// } from 'react'
// // import SearchComponent from '../../components/search'
// // import { debounce } from 'lodash'
// import { useNavigate } from 'react-router-dom'
// import { employeeDirectory } from '../../redux/Employee/employeeAction'
// import { useDispatch, useSelector } from "react-redux";
// import EmptyStateComponent from '../../components/Empty State/emptystate';
// import PaginationComponent from '../../components/Pagination/Pagination';


// const EmployeeTable = () => {
//   const dispatch = useDispatch();
//   const { loading, employees, total } = useSelector(
//     (state) => state.employee
//   );

//   //const [searchValue, setSearchValue] = useState('')
//   //pagination
//   const [currentPage, setCurrentPage] = useState(1)
//   const [pageSize, setPageSize] = useState(10);

//   const onPageSizeChange = (page) => {
//     setPageSize(page)

//     // Dispatch the employeeDirectory action with the updated page size
//     dispatch(employeeDirectory({
//       page: currentPage,
//       type: typeID,
//       page_size: page
//     }));
//   }

//   //navigate to individual employees
//   const navigate = useNavigate()

//   const handleEmployeePage = (id) => {
//     navigate(`/employee/${id}`)
//   }

//   //add tags to employee directory page
//   const [typeID, setTypeID] = useState(1);

//   const employeeTypes = [
//     {
//       id: 1,
//       title: "All",
//       tag: "all",
//     },
//     {
//       id: 2,
//       title: "Active",
//       tag: "active",
//     },
//     {
//       id: 3,
//       title: "Pending",
//       tag: "pending",
//     },
//     {
//       id: 4,
//       title: "Inactive",
//       tag: "inactive",
//     },
//   ];

//   const typeMap = { // Move typeMap *outside* the useEffect for broader scope
//     1: 'all',
//     2: 'active',
//     3: 'pending',
//     4: 'inactive',
//   };

//   const toggleType = (id, tag) => {
//     setTypeID(id);
//     const params = { page: 1, type: tag, page_size: pageSize };
//     if (id !== 1) params.type = "all";
//     dispatch(employeeDirectory(params));
//   };

//   useEffect(() => {
  
//     dispatch(employeeDirectory({
//       page: 1,
//       type: typeMap[typeID], 
//       page_size: pageSize,
//     }));
//   }, [dispatch, typeID, pageSize, typeMap]); 
  


//   const onPageChange = (val) => {
//     const { page } = val
//     setCurrentPage(page)
//     switch (typeID) {
//       case 1:
//         dispatch(
//           employeeDirectory({
//             page: page,
//             type: 'all',
//             page_size: pageSize
//           }),
//         )
//         break
//       case 2:
//         dispatch(
//           employeeDirectory({
//             page: page,
//             type: 'active',
//             page_size: pageSize
//           })
//         )
//         break
//       case 3:
//         dispatch(
//           employeeDirectory({
//             page: page,
//             type: 'pending',
//             page_size: pageSize
//           })
//         )
//         break
//       case 4:
//         dispatch(
//           employeeDirectory({
//             page: page,
//             type: 'inactive',
//             page_size: pageSize
//           })
//         )
//         break
//       default:
//         break
//     }
//   }





//   return (
//     <>
//       <div className="mt-4 bg-white rounded-[8px]">
//         <div className="p-4">
//           {/* search 
//                     <div className="">
//                         <SearchComponent
//                             placeholder="Search..."
//                             searchValue={searchValue}
//                             handleChange={handleChange}
//                         />
//                 </div> */}

//           <ul className="flex mt-5 gap-5 items-center">
//             {employeeTypes.map((item) => (
//               <li
//                 key={item.id}
//                 onClick={() => toggleType(item.id, item.tag)}
//                 className={
//                   typeID === item.id
//                     ? 'flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer'
//                     : 'flex gap-2 items-center pb-1 cursor-pointer'
//                 }
//               >
//                 <p
//                   className={
//                     typeID === item.id
//                       ? 'text-primary text-sm font-semibold'
//                       : 'text-[#96A397] text-sm font-semibold'
//                   }
//                 >
//                   {item.title}
//                 </p>
//               </li>
//             ))}
//           </ul>

//         </div>

//         {/* tables */}
//         <div className="overflow-x-auto relative">
//           <table className="w-full text-left">
//             <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
//               <tr>
//                 <th scope="col" className="px-4 py-4 font-semibold ">
//                   Employee ID
//                 </th>

//                 <th scope="col" className="px-4 py-4 font-semibold">
//                   First Name
//                 </th>
//                 <th scope="col" className="px-4 py-4 font-semibold">
//                   Last Name
//                 </th>
//                 <th scope="col" className="px-4 py-4 font-semibold">
//                   Role
//                 </th>
//                 <th scope="col" className="px-4 py-4 font-semibold">
//                   Pension ID
//                 </th>
//               </tr>
//             </thead>
//             {!loading && (
//               <tbody>
//                 {employees.length > 0 && (
//                   employees.map((item) => (
//                     <tr
//                       className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
//                       key={item.id}
//                       onClick={() => item.paytimeuserprofile && typeID !== 3 ? handleEmployeePage(item.id) : handleEmployeePage(item.paytimeuserprofile.id)}
//                     >
//                       <th
//                         scope="row"
//                         className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
//                       >
//                         {item.paytimeuserprofile !== null ? item.paytimeuserprofile.employee_id : "N/A"}
//                       </th>

//                       <td className="py-4 px-4 text-[#5C715E]">
//                         {item.first_name}
//                       </td>
//                       <td className="py-4 px-4 text-[#5C715E]">
//                         {item.last_name}
//                       </td>
//                       <td className="py-4 px-4 text-[#5C715E]">
//                         {item.paytimeuserprofile && item.paytimeuserprofile.role !== null && item.paytimeuserprofile.role !== "" ? item.paytimeuserprofile.role : "-"}
//                       </td>
//                       <td className="py-4 px-4">
//                         {item.paytimeuserprofile && item.paytimeuserprofile.pension_id !== null && item.paytimeuserprofile.pension_id !== "" ? item.paytimeuserprofile.pension_id : "-"}
//                       </td>
//                     </tr>
//                   ))
//                 )
//                 }

//               </tbody>
//             )}
//           </table>

//           {loading && (
//             <div className="animate-pulse w-full mt-4 px-4 mb-4">
//               <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
//               <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
//               <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
//               <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
//               <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
//               <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
//             </div>
//           )}
//         </div>
//       </div>
//       {!loading && employees.length < 1 && (
//         <EmptyStateComponent textContent="Oops! No employees found" />
//       )}
//       {!loading && employees.length > 0 ? (
//         <div className="">
//           <PaginationComponent
//             page={currentPage}
//             onPageChange={(page) => onPageChange({ page })}
//             totalItems={total}
//             pageSize={pageSize}
//             pageSizeShow
//             onPageSizeChange={(page) => onPageSizeChange(page)}
//           />
//         </div>
//       ) : (
//         ''
//       )}

//     </>
//   )
// }

// export default EmployeeTable

import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { employeeDirectory } from '../../redux/Employee/employeeAction';
import EmptyStateComponent from '../../components/Empty State/emptystate';
import PaginationComponent from '../../components/Pagination/Pagination';

const EmployeeTable = () => {
  const dispatch = useDispatch();
  const { loading, employees, total } = useSelector((state) => state.employee);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [typeID, setTypeID] = useState(1);
  const navigate = useNavigate();

  const employeeTypes = [
    { id: 1, title: "All", tag: "all" },
    { id: 2, title: "Active", tag: "active" },
    { id: 3, title: "Pending", tag: "pending" },
    { id: 4, title: "Inactive", tag: "inactive" },
  ];

  // Use useMemo to prevent recreation on every render
  const typeMap = useMemo(() => ({
    1: 'all',
    2: 'active',
    3: 'pending',
    4: 'inactive',
  }), []);

  const handleEmployeePage = (id) => {
    navigate(`/employee/${id}`);
  };

  const toggleType = (id) => {
    setTypeID(id);
    dispatch(employeeDirectory({ page: 1, type: typeMap[id], page_size: pageSize }));
  };

  useEffect(() => {
    dispatch(employeeDirectory({ page: 1, type: typeMap[typeID], page_size: pageSize }));
  }, [dispatch, typeID, pageSize, typeMap]);

  const onPageChange = (val) => {
    setCurrentPage(val.page);
    dispatch(employeeDirectory({ page: val.page, type: typeMap[typeID], page_size: pageSize }));
  };

  return (
    <>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          <ul className="flex mt-5 gap-5 items-center">
            {employeeTypes.map((item) => (
              <li
                key={item.id}
                onClick={() => toggleType(item.id)}
                className={`flex gap-1 items-center pb-1 cursor-pointer ${
                  typeID === item.id ? 'border-b-2 border-primary' : ''
                }`}
              >
                <p className={`text-sm font-semibold ${typeID === item.id ? 'text-primary' : 'text-[#96A397]'}`}>
                  {item.title}
                </p>
              </li>
            ))}
          </ul>
        </div>

        {/* Table */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th className="px-4 py-4 font-semibold">Employee ID</th>
                <th className="px-4 py-4 font-semibold">First Name</th>
                <th className="px-4 py-4 font-semibold">Last Name</th>
                <th className="px-4 py-4 font-semibold">Role</th>
                <th className="px-4 py-4 font-semibold">Pension ID</th>
              </tr>
            </thead>
            {!loading && employees.length > 0 && (
              <tbody>
                {employees.map((item) => (
                  <tr
                    className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                    key={item.id}
                    onClick={() =>
                      item.paytimeuserprofile && typeID !== 3
                        ? handleEmployeePage(item.id)
                        : handleEmployeePage(item.paytimeuserprofile?.id || item.id)
                    }
                  >
                    <th className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap">
                      {item.paytimeuserprofile?.employee_id || "N/A"}
                    </th>
                    <td className="py-4 px-4 text-[#5C715E]">{item.first_name}</td>
                    <td className="py-4 px-4 text-[#5C715E]">{item.last_name}</td>
                    <td className="py-4 px-4 text-[#5C715E]">{item.paytimeuserprofile?.role || "-"}</td>
                    <td className="py-4 px-4">{item.paytimeuserprofile?.pension_id || "-"}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>

          {loading && (
            <div className="animate-pulse w-full mt-4 px-4 mb-4">
              {[...Array(6)].map((_, i) => (
                <div key={i} className="h-10 bg-gray-200 rounded-lg w-full mb-2"></div>
              ))}
            </div>
          )}
        </div>
      </div>

      {!loading && employees.length < 1 && <EmptyStateComponent textContent="Oops! No employees found" />}
      
      {!loading && employees.length > 0 && (
        <PaginationComponent
          page={currentPage}
          onPageChange={onPageChange}
          totalItems={total}
          pageSize={pageSize}
          pageSizeShow
          onPageSizeChange={setPageSize}
        />
      )}
    </>
  );
};

export default EmployeeTable;
