import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../config";
import cogoToast from "cogo-toast"
import { useNavigate } from "react-router-dom";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};



//list all payrolls
export const listPayrolls = createAsyncThunk(
  "listPayrolls",
  async ({ page, page_size }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}payrolls/?page_size=${page_size}&page=${page}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
        const navigate = useNavigate()
        navigate('/login')
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const listPayslipsUnderPayroll = createAsyncThunk(
  "PayslipsUnderPayroll",
  async ({ id, type, page, page_size }, { rejectWithValue, dispatch }) => {
    try {
      if (type === "all") {
        const res = await axios.get(`${apiUrl}payroll/${id}/payslips?page_size=${page_size}&page=${page}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res.data;
        }
      } else {
        const res = await axios.get(
          `${apiUrl}payroll/${id}/payslips?status=${type}&page_size=${page_size}&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
        const navigate = useNavigate()
        navigate('/login')
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const resetCheckedApprovedAllowance = () => {
  return (state) => {
    return {
      ...state,
      checkedApprovedAllowance: [],
    };
  };
};

//list allowance under payroll
export const listAllowanceUnderPayroll = createAsyncThunk(
  "AllowanceUnderPayroll",
  async ({ id, type, page, page_size }, { rejectWithValue, dispatch }) => {
    try {
      if (type === "all") {
        const res = await axios.get(`${apiUrl}payroll/${id}/allowance?page_size=${page_size}&page=${page}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res.data;
        }
      } else {
        const res = await axios.get(
          `${apiUrl}payroll/${id}/allowance?status=${type}&page_size=${page_size}&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

//list approved payslip
export const listApprovedPayslips = createAsyncThunk(
  "approvedPayslips",
  async ({ type, page, page_size }, { rejectWithValue, dispatch }) => {
    try {
      if (type === "all") {
        const res = await axios.get(`${apiUrl}payroll/approvedpayslips?page_size=${page_size}&page=${page}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          console.log("type not seen")
          return res.data;
        }
      } else {
        const res = await axios.get(
          `${apiUrl}payroll/approvedpayslips?payment_status=${type}&page_size=${page_size}&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          console.log("type seen")
          return res.data;
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

//approve payslip endpoint
export const createApprovePayslip = createAsyncThunk(
  "createApprovePayslip",
  async ({ payroll_id }, { rejectWithValue, dispatch, getState }) => {
    let payslip = getState().payroll.checkedPayslips
    try {
      const res = await axios.post(`${apiUrl}payslip/approve/`,
        {
          payroll_id: payroll_id,
          payslip: payslip
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
      if (res.status === 200) {
        cogoToast.success(`Payslip(s) approved successfully!`);
        return res.data;

      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
        const navigate = useNavigate()
        navigate('/login')
      } else if (error.response.status === 400) {
        cogoToast.error(`${error.response.data.error || error.response.data.message || error.response.data.detail}`)
      } else {
        return rejectWithValue(error.response.data.error);
      }
    }
  }
);

//approve allowance endpoint  
export const createApproveAllowance = createAsyncThunk(
  "createApproveAllowance",
  async ({ payroll_id }, { rejectWithValue, dispatch, getState }) => {
    let payslip = getState().payroll.checkedAllowance
    try {
      const res = await axios.post(`${apiUrl}payslip/allowance/approve/`,
        {
          payroll_id: payroll_id,
          payslip: payslip
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
      if (res.status === 200) {
        cogoToast.success(res.data.message);
        return res.data;

      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
        const navigate = useNavigate()
        navigate('/login')
      } else if (error.response.status === 400) {
        cogoToast.error(`${error.response.data.error || error.response.data.message || error.response.data.detail}`)
      } else {
        return rejectWithValue(error.response.data.error);
      }
    }
  }
);

//make payment 
export const makePayslipPayment = createAsyncThunk(
  "makePayslipPayment",
  async (arg, { rejectWithValue, dispatch, getState }) => {
    let payslip = getState().payroll.checkedApprovedPayslips
    try {
      // console.log("making payments")
      const res = await axios.post(`${apiUrl}payslip/pay/`,
        {
          employee: payslip
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
      if (res.status === 202) {
        cogoToast.success(res.message)
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
        const navigate = useNavigate()
        navigate('/login')
      } else if (error.response.status === 400) {
        cogoToast.error(`${error.response.data.error || error.response.data.message || error.response.data.detail}`)
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

//make Allowance Payment
export const makeAllowancePayment = createAsyncThunk(
  "makeAllowancePayment",
  async (arg, { rejectWithValue, dispatch, getState }) => {
    let payslip = getState().payroll.checkedApprovedAllowance
    try {
      const res = await axios.post(`${apiUrl}payslip/allowance/pay/`,
        {
          employee: payslip
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
      if (res.status === 202) {
        cogoToast.success(res.data.message)
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
        const navigate = useNavigate()
        navigate('/login')
      } else if (error.response.status === 400) {
        cogoToast.error(`${error.response.data.error || error.response.data.message || error.response.data.detail}`)
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

// regenerate payslip
export const regeneratePayslip = createAsyncThunk(
  "regeneratePayslip",
  async ({ payroll_id, date_of_entry, payslip_date_of_entry }, { rejectWithValue, dispatch, getState }) => {
    let employees = getState().payroll.checkedRegeneratedPayslips
    try {
      const res = await axios.post(`${apiUrl}payslip/regenerate/`,
        {
          payroll_id: payroll_id,
          employees: employees,
          date_of_entry: date_of_entry,
          payslip_date_of_entry: payslip_date_of_entry
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
      if (res.status === 201) {
        cogoToast.success(`Payslip(s) regenerated succesfully`);
        return res.data;

      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
        const navigate = useNavigate()
        navigate('/login')
      } else if (error.response.status === 400) {
        cogoToast.error(error.response.data.error || error.response.data.message || error.response.data.detail)
        return rejectWithValue(error.response.data.error);
      }
    }
  }
);

//list approved allowance
export const listApprovedAllowance = createAsyncThunk(
  "approvedAllowance",
  async ({ type, page, page_size }, { rejectWithValue, dispatch }) => {
    try {
      if (type === "all") {
        const res = await axios.get(`${apiUrl}payroll/approvedallowance?page_size=${page_size}&page=${page}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res.data;
        }
      } else {
        const res = await axios.get(
          `${apiUrl}payroll/approvedallowance?payment_status=${type}&page_size=${page_size}&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
        const navigate = useNavigate()
        navigate('/login')
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

//exempt founders paye
export const exemptPaye = createAsyncThunk(
  "exemptPaye",
  async ({ employee_id, payslip_id, }, { rejectWithValue, dispatch }) => {

    try {
      const res = await axios.post(`${apiUrl}payslip/approve/`,
        {
          employee_id,
          payslip_id
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
      if (res.status === 200) {
        cogoToast.success(`Paye exemption was succresfull!`);
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response.status === 400) {
        cogoToast.error(`${error.response.data.error || error.response.data.message || error.response.data.detail}`)
      } else {
        return rejectWithValue(error.response.data.error);
      }
    }
  }
);

//send bulk email to all employees
export const sendBulkEmail = createAsyncThunk(
  "sendBulkEmail",
  async ({ date_of_entry}, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}payslip/send-to-emails/?date_of_entry=${date_of_entry}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if ([200, 201, 202].includes(res.status)) {
          cogoToast.success(res.data.message || "Email was sent successfully!");
          return res.data
        }    
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response.status === 400) {
        cogoToast.error(`${error.response.data.error} || ${error.response.data.message} || ${error.response.data.detail}`)
      } else {
        return rejectWithValue(error.response.data.error);
      }
    }
  } 
);